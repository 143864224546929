<template>
  <div>
    <div v-if="isLoading">
      <template v-for="index in 18">
        <v-skeleton-loader
          ref="skeleton"
          type="list-item-avatar-two-line"
          class="mx-auto"
          :key="index"
        />
      </template>
    </div>
    <v-tabs-items v-model="selectedAppBarTab">
      <v-tab-item
        v-for="tab in tabs"
        :value="tab.key"
        :key="tab.key"
      >
        <BookingList
          v-if="tab.key === 'all'"
          :bookings="bookings"
          length="50"
          step="50"
          :is-event-booking="true"
          v-on="$listeners"
          @filterBookingsExport="filterBookingsExport"
        />
        <BookingList
          v-if="tab.key === 'active'"
          :bookings="activeBookings"
          length="50"
          step="50"
          :is-event-booking="true"
          v-on="$listeners"
          @filterBookingsExport="filterBookingsExport"
        />
        <BookingList
          v-if="tab.key === 'cancelled'"
          :bookings="cancelledBookings"
          length="50"
          step="50"
          :is-event-booking="true"
          v-on="$listeners"
          @filterBookingsExport="filterBookingsExport"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>

import BookingList from '@/components/BookingList'

export default {
  name: 'EventBookings',
  components: {
      BookingList
  },
  computed: {
    selectedAppBarTab() {
			return this.$store.state.selectedAppBarTab
    },
    tabs() {
      return [
        {
          title: this.$t('all'),
          key: 'all',
          badge: {
            color: 'green',
            content: this.allBookingsCount
          }
        },
             {
          title: this.$t('active'),
          key: 'active',
          badge: {
            color: 'green',
            content: this.activeBookingsCount
          }
        },
        {
          title: this.$t('cancelled'),
          key: 'cancelled',
          badge: {
            color: 'red',
            content: this.cancelledBookingsCount
          }
        }
      ]
    },
    activeBookingsCount() {
      return this.activeBookings !== null ? this.activeBookings.length : 0
    },
    cancelledBookingsCount() {
      return this.cancelledBookings !== null ? this.cancelledBookings.length : 0
    },
    allBookingsCount() {
      return this.bookings !== null ? this.bookings.length : 0
    },
    activeBookings() {
      if(this.bookings === null || this.bookings === undefined){
        return null
      }
      return this.bookings.filter(bookings => {
        return bookings.status == "1"
      })
    },
    cancelledBookings() {
      if(this.bookings === null || this.bookings === undefined){
        return null
      }
      return this.bookings.filter(bookings => {
        return bookings.status == "0"
      })
    }
  },
  data: () => ({
    bookings: null,
    item: null,
    filteredBookings: null,
    isLoading: true
  }),
  mounted() {
    this.$store.commit('updateAppBarExport', null)
    this.$store.commit('updateAppBarExport', {
      title: this.$t('exportBookings'),
      callback: this.exportBookings
    })
    this.item = this.$store.state.items.find(item => { return item.uuid === this.$route.params.uuid})
    let item = this.item
    this.$store.dispatch('getBookingsByItem', item.uuid).then(result => {
      if(result === null || result === undefined){
        this.bookings = []
        this.Loading = false
      }
      this.bookings = this.sortBookingsByStartTime(result)
      this.isLoading = false
    })
  },
  watch: {
    bookings: function (newBookings, oldBookings) {
      if(newBookings !== null && oldBookings === null) {
        this.$store.commit('updateAppBarTabs', this.tabs)
      }
    }
  },
  methods: {
    exportBookings() {
        if(this.filteredBookings !== null){
          this.$store.commit('updateExportObjects', this.filteredBookings)
          return
        }
        this.$store.commit('updateExportObjects', this.bookings)
		},
    sortBookingsByStartTime(bookings){
    const SortedBookings = bookings.sort((a, b) => b.startsAtTime - a.startsAtTime)
    return SortedBookings
    },
    filterBookingsExport(filteredBookings) {
      this.filteredBookings = filteredBookings
    }
  }
}
</script>
